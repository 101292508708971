<template>
    <div class="swiper-main">
        <swiper :options="swiperOption">
            <swiper-slide
                v-for="(item, index) in swiperList"
                :key="index"
            >
                <img :src="item.imgUrl" />
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
    name: "Swiper",
    props: {
        swiperList: Array,
    },
    data() {
        return {
            swiperOption: {
                autoplay: 3000,
                speed: 1000,
                // 圆点
                pagination: {
                    el: ".swiper-pagination",
                },
            },
        };
    },
    components: {
        swiper,
        swiperSlide,
    },
};
</script>

<style scoped>
.swiper-main {
    width: 100%;
    height: 4.4rem;
}
.swiper-main img {
    width: 100%;
    height: 4.4rem;
}
.swiper-containe {
    width: 100%;
    height: 4.4rem;
    /* position: relative; */
}
.swiper-pagination {
    position: absolute;
    width: 100%;
}
::v-deep .swiper-pagination-bullet-active {
    background-color: #b0352f;
}
::v-deep .swiper-pagination-bullet {
    /* padding: 5.333333rem; */
    position: relative;
    bottom: 25px;
    margin: 0 0.133333rem;
}
</style>
