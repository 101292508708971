<template>
    <header>
        <h1>
            <div>茶购啦</div>
        </h1>
        <div
            class="search"
            @click="goSearch"
        >
            <i class="iconfont icon-Magnifier"></i>
            <span>
                搜您喜欢的.....
            </span>
        </div>

        <div class="kefu">
            <i class="iconfont icon-kefu"></i>
        </div>
    </header>
</template>

<script>
export default {
    methods: {
        goSearch() {
            this.$router.push("/search");
        },
    },
};
</script>

<style scoped>
header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    height: 1.17333rem;
    background: #46c6f5;
}
header h1 {
    width: 2.6rem;
    height: 1.173333rem;
}
header h1 div {
    padding-top:0.1rem ;
    padding-left:0.1rem ;
    text-align: center;
    color: #fff;
}
.search {
    margin-left: 0.053333rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 6.56rem;
    height: 0.8rem;
    background-color: #fff;
    border-radius: 0.4rem;
}
.search i {
    font-size: 0.533333rem;
    padding: 0 0.16rem;
    color: #46c6f5;
}
.search span {
    font-size: 0.373333rem;
    color: #ccc;
}
.kefu i {
    font-size: 0.96rem;
    color: #fff;
}
</style>