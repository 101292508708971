import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import Vue from 'vue';
import Vant from 'vant';
import { Lazyload } from "vant";
import 'vant/lib/index.css';

import fastClick from "fastclick";
fastClick.attach(document.body)

// element ui 

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// vantui
Vue.use(Vant);
Vue.use(Lazyload)
// 公共css文件
import "@/assets/css/common.css";

// 字体图标css
import "@/assets/css/iconfont.css";

Vue.config.productionTip = false;

// 引入 ly-tab插件
import LyTab from "ly-tab";

Vue.use(LyTab);
// 淘宝无限适配
import "@/assets/js/flexible.js"

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
