<template>
    <div class="recommend">
        <Card>
            <span>爆款推荐</span>
        </Card>
        <ul>
            <li
                v-for="(item, index) in recommendList"
                :key="index"
            >
                <h2><img :src="item.imgUrl" /></h2>
                <div>
                    <h3>{{ item.name }}</h3>
                    <p>{{ item.content }}</p>
                    <div class="price">
                        <span>￥</span>
                        <b>{{ item.price }}</b>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Card from "@/components/home/Card.vue";
export default {
    props: {
        recommendList: Array,
    },
    components: {
        Card,
    },
};
</script>

<style scoped>
.recommend ul li {
    position: relative;
    text-align: center;
}
.recommend ul li > div {
    position: absolute;
    right: 0rem;
    top: 0rem;
    display: flex;
    flex-direction: column;
    padding: 0.533333rem;
    padding-top: 0.8rem;
}
.recommend ul li h2 > img {
    width: 9.6rem;
    height: 3.84rem;
}
.recommend ul li div > h3 {
    text-align: right;
    font-size: 0.32rem;
    color: #666666;
}
.recommend ul li div > p {
    margin-top: 0.08rem;
    font-size: 0.4rem;
}
.price {
    color: #46c6f5;
    margin-top: 0.533333rem;
    text-align: right;
    font-size: 0.48rem;
}
</style>
