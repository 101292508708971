// 用户的数据
export const USER_LOGIN = "USER_LOGIN";
export const INIT_USER = "INIT_USER";
export const LOGIN_OUT = "loginOut";

// 购物车的数据
export const CART_LIST = "cartList";
export const CHECK_ALL = "checkAll";
export const UN_CHECK_ALL = "unCheckAll";
export const CHECK_ITEM = "checkItem";

// 收货地址
export const INIT_DATA = "initData";

// 订单
export const INIT_ORDER = "initOrder";