import { Toast } from "vant";
import axios from "axios";
import store from "@/store";
import router from "@/router";
export default {
    common: {
        method: "GET",
        data: {},
        params: {},
        headers: {}
    },
    axios(options = {}) {
        options.method = options.method || this.common.method;
        options.data = options.data || this.common.data;
        options.params = options.params || this.common.params;
        options.headers = options.headers || this.common.headers;

        // 请求前 ==》显示加载中
        Toast.loading({
            message: "加载中...",
            forbidClick: true,
        });

        // 是否是登录状态
        if (options.headers.token) {
            options.headers.token = store.state.user.token;
            if (!options.headers.token) {
                router.push("/login");
            }
        }

        return axios(options).then(v => {
            // console.log(v);
            let data = v.data.data;
            // 如果token过期重新登陆
            if (data.code == 1000) {
                Toast.clear();
                return router.push("/login")
            }
            return new Promise((res, rej) => {
                if (!v) return rej();
                // 结束加载状态
                setTimeout(() => {
                    Toast.clear();
                }, 500)
                res(data)
            })
        })
    }
}