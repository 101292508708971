import { INIT_ORDER } from "./mutation-types"
export default {
    state: {
        list: [],
        order_Id: localStorage.getItem("tea_orderId") || ""
    },
    mutations: {
        [INIT_ORDER](state, orderId) {
            state.list = orderId
            // 存储订单号
            state.order_Id = orderId[0].order_id;
            // console.log(state.order_Id);
            // 设置一个
            localStorage.setItem("tea_orderId", orderId[0].order_id)
        }
    }
}