<template>
    <ul class="icons">
        <li
            v-for="(item, index) in iconsList"
            :key="index"
        >
            <span>{{ item.title }}</span>
            <img
                :src="item.imgUrl"
                alt=""
            />
        </li>
    </ul>
</template>

<script>
export default {
    name: "Icons",
    props: {
        iconsList: Array,
    },
};
</script>

<style scoped>
.icons {
    margin-top: 0.2667rem;
    display: flex;
    justify-content: space-around;
}
.icons li {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}
.icons img {
    width: 1.013333rem;
    height: 1.013333rem;
}
.icons span {
    padding: 0.053333rem 0;
    font-size: 0.426666rem;
}
</style>
