<template>
    <div class="tabbar">
        <ul>
            <li
                v-for="(item, index) in routerList"
                :key="index"
                @click="switchTab(item.path)"
            >
                <img
                    :src="
            $router.history.current.path.includes(item.path)
              ? item.selected
              : item.active
          "
                    alt=""
                />
                <span :class="
            $router.history.current.path.includes(item.path) ? 'active' : ''
          ">{{ item.title }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            routerList: [
                {
                    title: "首页",
                    path: "/home",
                    active: "/images/home.png",
                    selected: "/images/home-select.png",
                },
                {
                    title: "分类",
                    path: "/list",
                    active: "/images/list.png",
                    selected: "/images/list-select.png",
                },
                {
                    title: "购物车",
                    path: "/cart",
                    active: "/images/cart.png",
                    selected: "/images/cart-select.png",
                },
                {
                    title: "我的",
                    path: "/my",
                    active: "/images/my.png",
                    selected: "/images/my-select.png",
                },
            ],
        };
    },
    methods: {
        switchTab(path) {
            // console.log(this.$router);
            // 判断是否点击的同一个路由 vue-router
            if (this.$router.history.current.path == path) return;
            //对应路由跳转页面
            this.$router.push(path);
        },
    },
};
</script>

<style scoped>
.tabbar {
    /* position: fixed;
    left: 0;
    bottom: 0; */
    z-index: 999;
    width: 100%;
    height: 1.6rem;
    background-color: #fff;
}
.tabbar ul {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
}
.tabbar ul li {
    display: flex;
    /* flex: 1; */
    text-align: center;
    flex-direction: column;
}
.tabbar ul li img {
    width: 0.83rem;
    height: 0.83rem;
}
.tabbar ul li span {
    font-size: 0.4rem;
}
.active {
    color: #77d2f3;
}
</style>
