<template>
    <div class="container">
        <div class="headers">
            <div class="headers-main">
                <Header></Header>
                <ly-tab
                    v-model="selectedId"
                    :items="items"
                    :options="options"
                    @change="changeTab"
                >
                </ly-tab>
            </div>
        </div>
        <section ref="wrapper">
            <div>
                <div
                    v-for="(item, index) in newData"
                    :key="index"
                >
                    <!-- {{item.data}} -->
                    <Swiper
                        v-if="item.type == 'swiperList'"
                        :swiperList="item.data"
                    ></Swiper>
                    <Icons
                        v-if="item.type == 'iconsList'"
                        :iconsList="item.data"
                    ></Icons>
                    <Recommend
                        v-if="item.type == 'recommendList'"
                        :recommendList="item.data"
                    ></Recommend>
                    <Ad
                        v-if="item.type == 'adList'"
                        :adList="item.data"
                    ></Ad>
                    <Like
                        v-if="item.type == 'likeList'"
                        :likeList="item.data"
                    ></Like>
                </div>
            </div>
        </section>
        <Tabbar></Tabbar>
    </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Swiper from "@/components/home/Swiper.vue";
import Icons from "@/components/home/Icons.vue";
import Recommend from "@/components/home/Recommend.vue";
import Tabbar from "@/components/common/Tabbar.vue";
import Like from "@/components/home/Like.vue";
import Ad from "@/components/home/Ad.vue";

// 引入插件
import BetterScroll from "better-scroll";

import http from "@/common/api/request";
export default {
    name: "HomeView",
    data() {
        return {
            selectedId: 0,
            items: [],
            newData: [],
            oBetterScroll: "",
            tBetterScroll: "",
            options: {
                activeColor: "#46c6f5",
                // 可在这里指定labelKey为你数据里文字对应的字段
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            let res = await http.axios({
                url: "/api/index_list/0/data/1",
                data: {},
                params: {},
                methods: "post",
            });

            this.items = Object.freeze(res.topBar);
            this.newData = Object.freeze(res.data);
            this.$nextTick(() => {
                this.tBetterScroll = new BetterScroll(this.$refs.wrapper, {
                    movable: true,
                    zoom: true,
                    click: true,
                });
            });
        },
        changeTab(item, index) {
            // console.log(item);
            this.addData(index);
        },

        async addData(index) {
            const res = await http.axios({
                url: `/api/index_list/${index}/data/1`,
            });

            if (res.constructor != Array) {
                this.newData = res.data;
            } else {
                this.newData = res;
            }
            console.log(this.newData);

            this.$nextTick(() => {
                this.oBetterScroll = new BetterScroll(this.$refs.wrapper, {
                    movable: true,
                    zoom: true,
                    click: true,
                });
            });
        },
    },
    components: {
        Header,
        Swiper,
        Icons,
        Recommend,
        Tabbar,
        Like,
        Ad,
    },
};
</script>
  
<style scoped>
::v-deep .ly-tabbar {
    box-shadow: none;
    border-bottom: none;
}

.headers {
    width: 100%;
    height: 2.4rem;
}
.headers-main {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
}
section {
    overflow: hidden;
    flex: 1;
}
</style>
