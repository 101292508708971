<template>
    <div class="like">
        <Card><span>猜你喜欢</span></Card>
        <ul>
            <li
                v-for="(item,index) in likeList"
                :key="index"
                @click="goDetail(item.id)"
            >
                <h2>
                    <img v-lazy="item.imgUrl" />

                </h2>
                <h3>{{item.name}}</h3>
                <div>
                    <span>￥</span>
                    <b>{{item.price}}</b>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Card from "@/components/home/Card.vue";

export default {
    props: {
        likeList: Array,
    },
    components: {
        Card,
    },
    methods: {
        goDetail(id) {
            this.$router.push({
                path: "/detail",
                query: {
                    id,
                },
                // name: "Detail",
                // params: {
                //     id,
                // },
            });
        },
    },
};
</script>

<style scoped>
.like img {
    width: 4.693333rem;
    height: 4.693333rem;
}
.like ul {
    display: flex;
    flex-wrap: wrap;
}

.like ul li {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.like h3 {
    width: 93%;
    padding: 0.16rem;
    font-size: 0.373333rem;
    font-weight: 400;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.like ul li > div {
    color: #b0352f;
    width: 93%;
    /* text-align: left; */
    padding: 0.16rem;
    text-align: left;
}
.like ul li > div span {
    font-size: 0.32rem;
}
.like ul li > div b {
    font-size: 0.426666rem;
    font-weight: 600;
}
</style>